import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropType from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ElementPortableText from 'src/components/ElementPortableText/ElementPortableText';
import ElementShareLinks from 'src/components/ElementShareLinks/ElementShareLinks';
import NodeProjectCard from 'src/components/NodeProjectCard/NodeProjectCard';
import PageLayout from 'src/components/PageLayout/PageLayout';
import PageSEO from 'src/components/PageSEO/PageSEO';
import { useTheme } from 'src/hooks';
import imgFallback from 'src/utils/image';

function ProjectPage({ location, data: { project } }) {
  const t = useTheme();

  const seo = {
    title: project.pageSeo?.title ?? project.title,
    description: project.pageSeo?.description ?? project.description,
    image: project.pageSeo?.image?.asset?.url,
    location,
  };

  return (
    <PageLayout>
      <PageSEO {...seo} />
      <Container fluid className="bg-shapes-banner px-0 pt-9">
        <Container className="py-xl-6">
          {/* Main infos */}
          <Row className="py-xl-4">
            <Col xs={12} md={6} xl={{ span: 4, offset: 1 }}>
              <Col className="px-0">
                <h1 className="h2 mb-3">{project.title}</h1>
              </Col>
              <Col className="px-0">
                <Row className="align-items-center">
                  <Col xs="auto">
                    <GatsbyImage
                      alt="Image"
                      image={project.client.image?.image?.asset?.gatsbyImageData ?? imgFallback}
                      className="bg-gray-200"
                      style={{ width: '75px', height: '75px', borderRadius: '50%', overflow: 'hidden' }}
                      imgStyle={{ objectFit: 'contain', padding: '14px' }}
                    />
                  </Col>
                  <Col>
                    <h6 className="text-size-md mb-0">{project.client.name}</h6>
                    <a href={project.client.url} rel="nofollow" className="btn-link-primary-sm">
                      {project.client.url}
                    </a>
                  </Col>
                </Row>
              </Col>
            </Col>
            <Col xs={12} md={6} xl={{ span: 5, offset: 1 }}>
              <Row className="flex-column">
                <Col className="mb-3">
                  <div className="text-muted text-size-sm">{`${t.pages.project.services}:`}</div>
                  <h6 className="font-weight-bold">{project.services.join(', ')}</h6>
                </Col>
                <Col className="mb-3">
                  <div className="text-muted text-size-sm">{`${t.pages.project.industry}:`}</div>
                  <h6 className="font-weight-bold">{project.industry.join(', ')}</h6>
                </Col>
                <Col className="mb-3">
                  <div className="text-muted text-size-sm">{`${t.pages.project.term}:`}</div>
                  <h6 className="font-weight-bold">{project.term}</h6>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid>
        {/* Banner image and text(lead) */}
        <Row className="mb-xl-4">
          <Col xs={12} className="d-none d-sm-block px-0">
            <GatsbyImage image={project.banner?.image?.asset?.gatsbyImageData ?? imgFallback} alt={project.banner?.alt ?? ''} />
          </Col>
          <Col xs={12} className="d-sm-none px-0">
            <GatsbyImage image={project.bannerMobile?.image?.asset?.gatsbyImageData ?? imgFallback} alt={project.bannerMobile?.alt ?? ''} />
          </Col>
        </Row>
        <Container className="px-0">
          <Row>
            <Col xs={12} xl={{ span: 8, offset: 3 }} className="text-muted font-weight-bold py-4">
              {project.intro}
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        {/* Body elements */}
        <Row className="py-4">
          <Col className="py3" xs={12}>
            <ElementPortableText content={project._rawBody} variant={['post']} />
          </Col>
        </Row>
      </Container>
      {/* Share links */}
      <Container className="px-0 pb-7 pt-2">
        <Row noGutters>
          <Col xs={12} xl={{ span: 8, offset: 3 }}>
            <ElementShareLinks pathAlias={project.slug.current} title={seo.title} />
          </Col>
        </Row>
      </Container>

      {/* Related Projects Section */}
      {project.relatedProjects.length > 0 && (
        <Container fluid>
          <Container className="pb-6">
            <h2 className="text-md-center py-5">{t.pages.project.similarProjects}</h2>
            <Row>
              {project.relatedProjects.map((card) => (
                <Col key={card.id} xs={12} md={6} className="py-3">
                  <NodeProjectCard card={card} />
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      )}
    </PageLayout>
  );
}

ProjectPage.propTypes = {
  data: PropType.shape({
    project: PropType.shape({}),
  }).isRequired,
};

export const query = graphql`
  query ($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      pageSeo {
        ...getPageSeo
      }
      banner {
        alt
        image {
          asset {
            gatsbyImageData(aspectRatio: 3, layout: FULL_WIDTH)
          }
        }
      }
      bannerMobile: banner {
        alt
        image {
          asset {
            gatsbyImageData(aspectRatio: 1.5, width: 920)
          }
        }
      }
      previewImage {
        alt
        image {
          asset {
            gatsbyImageData(aspectRatio: 1.25, width: 600)
          }
        }
      }
      description
      services
      term
      industry
      category {
        id
        name
        color
      }
      client {
        image {
          image {
            asset {
              gatsbyImageData(width: 300, placeholder: BLURRED)
            }
          }
        }
        name
        url
      }
      intro
      _rawBody
      relatedProjects {
        ...getProjectsAsCards
      }
    }
  }
`;

export default ProjectPage;
